import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {CarouselModule} from 'ngx-bootstrap/carousel';

import {LandingPageComponent} from './landing-page/landing-page.component';
import {RouterModule} from "@angular/router";
import {NotFoundComponent} from "./not-found.component";
import {AppComponent} from "./app.component";
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {AppDemoCarousel} from "./landing-page/carousels/demo/demo-coursel.component";
import {FormsModule} from "@angular/forms";
import {HttpClientModule} from "@angular/common/http";
import {InquiryCompleteModal} from './landing-page/modals/inquiry-sent.modal';
import {VideoNotAvailableModal} from './landing-page/modals/video-not-available.modal';
import { TncsComponent } from './tncs/tncs.component';
import { FaqsComponent } from './faqs/faqs.component';
import {PrivacyComponent} from './privacy/privacy.component';


@NgModule({
    declarations: [
        AppComponent,
        AppDemoCarousel,
        InquiryCompleteModal,
        VideoNotAvailableModal,
        LandingPageComponent,
        NotFoundComponent,
        TncsComponent,
        FaqsComponent,
        PrivacyComponent
    ],
    imports: [
        BrowserModule,
        FormsModule,
        HttpClientModule,
        CarouselModule.forRoot(),
        NgbModule,
        RouterModule.forRoot(
            [
                {path: '', component: LandingPageComponent},
                {path: 'tncs', component: TncsComponent},
                {path: 'faqs', component: FaqsComponent},
                {path: 'privacy', component: PrivacyComponent},
                {path: '404', component: NotFoundComponent},
                {path: '**', redirectTo: '/404'}
            ]
        )
    ],
    entryComponents: [InquiryCompleteModal, VideoNotAvailableModal],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule {
}


