import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-faqs',
    templateUrl: './faqs.component.html',
    styleUrls: ['./faqs.component.css']
})
export class FaqsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
        window.scroll(0, 0);
    }

}
