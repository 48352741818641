/**
 * Created by Karl on 23/2/18.
 */
import {Component} from "@angular/core";

@Component({
    selector: 'app-root',
    template: '<router-outlet></router-outlet>'
}) export class AppComponent {

}
