import {Component} from "@angular/core";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
/**
 *
 * Created by Karl on 28/2/18.
 */
@Component({
    selector: 'inquiry-complete',
    template: `
            <div class="modal-content">
                <div class="modal-header">
                <button type="button" class="close" aria-label="Close" (click)="dismiss('Cross click')">
                    <span aria-hidden="true">&times;</span>
                </button>
                </div>
                <div class="modal-body">
                    <p class="">This video is currently unavailable.</p>
                </div>
             </div>
        `
})
export class VideoNotAvailableModal {

    constructor(private ngbActiveModal: NgbActiveModal) {
    }

    dismiss(message: string) {
        this.ngbActiveModal.dismiss(message)
    }

}
