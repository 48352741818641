import {Component, HostListener, ViewChild,  OnInit} from '@angular/core';
import {Location, LocationStrategy, PathLocationStrategy} from '@angular/common';

import {environment} from '../../environments/environment';


import {HttpClient} from '@angular/common/http';
import {InquiryCompleteModal} from './modals/inquiry-sent.modal';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {MessageResponse, InquiryForm} from '../models/models';

@Component({
    moduleId: module.id,
    selector: 'landing-page',
    templateUrl: './landing-page.component.html',
    styleUrls: ['./landing-page.component.css'],
    providers: [Location, {provide: LocationStrategy, useClass: PathLocationStrategy}]
})
export class LandingPageComponent implements OnInit {

    readonly HOME_SECTION = 0;
    readonly DEMO_SECTION = 1;
    readonly STORE_SECTION = 2;
    readonly CONTACT_US_SECTION = 3;

    readonly DEMO_APP_TEMPLATE =
        'Hi Tilly,\n\nWe are from <company>. We are interested in knowing more about Tilly and seeing it in action!';

    screenWidth: number;

    sectionIndex: number = this.HOME_SECTION;

    isHomeInfoShown: boolean = true;

    form: InquiryForm = {
        firstName: '',
        lastName: '',
        email: '',
        message: ''
    };

    @ViewChild('nav', {static: false}) nav;
    @ViewChild('home', {static: false}) homeDiv;
    @ViewChild('demo', {static: false}) demoDiv;
    @ViewChild('store', {static: false}) storeDiv;
    @ViewChild('contactus', {static: false}) contactUsDiv;

    constructor(private location: Location,
                private http: HttpClient,
                private modalService: NgbModal)
    {}

    ngOnInit(): void {
        console.log(this.location.path())
    }

    @HostListener('window:scroll', ['$event'])
    onScroll($event) {
        this.scrollSpy();
    }


    private isWindowTopInElem(elem) {
        const navTop = this.nav.nativeElement.offsetTop;
        const navBottom = navTop + this.nav.nativeElement.offsetHeight;

        const elemTop = elem.nativeElement.offsetTop;
        const elemBottom = elemTop + elem.nativeElement.offsetHeight;
        return elemTop <= navBottom && navBottom <= elemBottom
    }

    private scrollSpy() {
        // Bottom element first since jump-target overlaps with other views.
        if (this.isWindowTopInElem(this.contactUsDiv)) {
            if (this.sectionIndex != this.CONTACT_US_SECTION) {
                this.location.replaceState('/#contact-us')
            }
            this.setIndex(this.CONTACT_US_SECTION);
            return;
        }

        if (this.isWindowTopInElem(this.storeDiv)) {
            if (this.sectionIndex != this.STORE_SECTION) {
                this.location.replaceState('/#store')
            }
            this.setIndex(this.STORE_SECTION);
            return;
        }

        if (this.isWindowTopInElem(this.demoDiv)) {
            if (this.sectionIndex != this.DEMO_SECTION) {
                this.location.replaceState('/#demo')
            }
            this.setIndex(this.DEMO_SECTION);
            return;
        }

        if (this.isWindowTopInElem(this.homeDiv)) {
            if (this.sectionIndex != this.HOME_SECTION) {
                this.location.replaceState('/#home')
            }
            this.setIndex(this.HOME_SECTION);
        }
    }

    onPlayClicked($event) {
        this.isHomeInfoShown = false;
        setTimeout(this.onHomeInfoFadedOut, 500);
        $event.stopPropagation();

    }

    private onHomeInfoFadedOut() {
        const videoPlayer: HTMLVideoElement = <HTMLVideoElement> document.getElementById('promo');
        videoPlayer.play();
    }

    scrollTo(target) {
        target.scrollIntoView({behavior: 'smooth', block: 'start', inline: 'nearest'});
    }

    setIndex(index: number) {
        this.sectionIndex = index
    }

    submitInquiry() {

        const url: string = environment.opsMailerUrl + '/send-inquiry';

        this.http.post<MessageResponse>(url, this.form)
            .subscribe(response => {
                this.resetForm();
                this.openInquiryModal(response.message);
            });

    }

    private openInquiryModal(message: string) {
        const modalRef = this.modalService.open(InquiryCompleteModal);
        modalRef.componentInstance.message = message;
        modalRef.result.then(dismissed => {
            console.log('Dismissed!');
        })
    }

    onHomeContainerClick() {
        if (!this.isHomeInfoShown) {
            this.isHomeInfoShown = true;
            const videoPlayer: HTMLVideoElement = <HTMLVideoElement> document.getElementById('promo');
            videoPlayer.pause();
        }
    }

    onDemoAppClicked() {
        this.form.message = this.DEMO_APP_TEMPLATE;
    }

    private resetForm() {
        this.form = {
            firstName: '',
            lastName: '',
            email: '',
            message: ''
        };
    }
}
