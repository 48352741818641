import {Component, OnInit} from '@angular/core';

@Component({
    selector: 'app-tncs',
    templateUrl: './tncs.component.html',
    styleUrls: ['./tncs.component.css']
})
export class TncsComponent implements OnInit {

    constructor() {
    }

    ngOnInit() {
        window.scroll(0, 0);
    }

}
