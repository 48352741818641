/**
 *
 * Created by Karl on 23/2/18.
 */
import {Component} from "@angular/core";
import {NgbCarouselConfig} from "@ng-bootstrap/ng-bootstrap";
@Component({
    selector: 'demo-carousel',
    templateUrl: 'demo-carousel.component.html',
    providers: [NgbCarouselConfig],
    styleUrls: ['./demo-carousel.component.css']
})
export class AppDemoCarousel {

    constructor(config: NgbCarouselConfig) {
        config.interval = 3750;
        config.wrap = true;
        config.keyboard = true;
    }
}
